<template>
  <div class="userCentRightCens">
    <!-- <p>最近直播</p> -->
    <div class="love-two-title">
      <div class="text">
        <div>最近直播</div>
        <img class="icon" src="@/assets/img/Home/tips.png">
      </div>
    </div>
    <!-- 缩宽 -->
    <div class="userCentRightCenss">
      <div class="week-list">
        <div v-for="(item, index) in times" :key="index" class="week-item" :class="same_day == item.date ? 'on' : ''"
          @click="select(item.date)">
          <div class="day">{{ item.day }}</div>
          <div class="week">
            {{ weekEnum[item.week] ? weekEnum[item.week].slice(2) : "" }}
          </div>
        </div>
      </div>
      <div class="ov-box">
        <div v-for="(item, index) in zb" :key="index" class="daytCont" @click="check(item)">
          <div class="courseName">{{ item.classesName }}</div>
          <!-- <div class="courseName">{{ item.classesName }}</div> -->
          <div class="dfbox">
            <img :src="item.courseImg" class="item-img">
            <!-- 左侧详情 -->
            <!-- <div class="content">
              <div class="zjTitle twoEllipsis">{{ item.title }}</div>
              <div class="but">立即观看</div>
            </div> -->

            <div class="content">
              <div class="zjTitle twoEllipsis">{{ item.title }}</div>
              <!-- <div class="dateBox">
                <div class="date">{{ item.startTime }}</div>
                <div
                  :class="item.isLive == 1 ? 'active' : ''"
                  @click.stop="gkZb(item, 1)"
                  class="but"
                >
                  {{ item.isLive == 1 ? '直播中' : '看回放' }}
                </div>
              </div> -->
              <div class="dateBox">
                <div v-if="item.isLive == 0" class="date">
                  {{
                    `${item.startTime.substring(
                      5,
                      7
                    )}月${item.startTime.substring(
                      8,
                      10
                    )}日 ${item.startTime.substring(11, 16)}开播`
                  }}
                </div>
                <div v-else class="date">
                  {{ `${item.startTime.substring(5, 16)}` }}
                </div>
                <div v-if="item.isLive != 0" class="but fr" @click.stop="gkZb(item, 1)">
                  {{ item.isLive == 1 ? "直播中" : "看回放" }}
                </div>
              </div>
            </div>
            <!--
          <div class="content">
            <div class="zjTitle twoEllipsis">{{ item.title }}</div>
            <div @click.stop="gkcb(item)" class="but">看回放</div>
          </div> -->
          </div>
        </div>
      </div>
      <div class="daytCon">
        <!-- 直播课表内容1 -->
      </div>
    </div>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ""
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getDnsOneApi } from '@/api/home'
import {
  getUnderwayPcAllList,
  // getUidClassList,
  getUserCurTracking
} from '@/api/user'
import { TimeFunction } from '@/api/TimeFunction'
const timeFunction = new TimeFunction()
import Vue from 'vue'
import { weekEnum } from '@/api/emun'
import { courseCheckPlay } from '@/api/know'
export default {
  components: {},

  data() {
    return {
      sub: '',
      tenantId: '',
      weekEnum: weekEnum,
      times: [],
      survey: '', // 学习概况
      name: '',
      same_day: '',
      names: '',
      zb: [],
      imageUrl: '',
      user: '',
      weekday: [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六'
      ],
      lookDeatil: null,
      showLookDetail: false
    }
  },
  async created() {
    await getDnsOneApi().then((res) => {
      if (res.code == 200) {
        this.tenantId = res.msg.entrants.tenantId
      }
    })
    await this.getDate()
    this.userId = localStorage.getItem('userId')
    // 调用学习概况接口方法
    this.loadgetUserCurTracking()
    // 调用获取学生直播课信息
    this.getList()
  },
  methods: {
    // 最近直播时间
    async getDate() {
      this.times = []
      for (let i = 0; i < 7; i++) {
        timeFunction.fun_date(i).then((res) => {
          const data = {
            date: res,
            day: new Date(res).getUTCDate(),
            week: i == 0 ? 7 : Number(new Date(res).getUTCDay())
          }
          if (i == 0) {
            this.same_day = data.date
          }
          this.times.push(data)
        })
      }
    },
    // 学习概况接口方法
    loadgetUserCurTracking() {
      getUserCurTracking(this.userId).then((res) => {
        if (res.code == 0) {
          for (const i in res.msg) {
            const item = res.msg[i]
            if (i == 'sumTime' || i == 'todayTime') {
              const a = item.indexOf('小时')
              const b = item.indexOf('分')
              const data = {
                hour: 0,
                minute: 0
              }
              if (a != -1) {
                data.hour = item.substr(0, a)
              }
              if (b != -1) {
                data.minute = item.substr(a != -1 ? a + 2 : 0, b - 3)
              }
              res.msg[i] = data
            }
          }
          this.survey = res.msg
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
    },
    // 调用获取学生直播课信息(点击)res.code==0
    getList() {
      getUnderwayPcAllList(this.userId, this.same_day).then((res) => {
        if (res.code == 0) {
          // for (let item of res.msg.cla) {
          //   item.liveType = 1
          //   item.title = item.tiitle
          // }
          // for (let item of res.msg.live) {
          //   item.liveType = 2
          // }
          // this.zb = res.msg.cla.concat(res.msg.live
          this.zb = res.data
          // this.zb.forEach((e) => {
          //   e.startTime = e.startTime.slice(5, 16)
          // })
        }
      })
    },

    select(iemw) {
      this.same_day = iemw
      // 调用获取学生直播课信息
      this.getList()
    },
    // 观看回放
    // check(item) {
    //   if (item.liveType == 1) {
    //     if (item.productLine == 3) {
    //       this.$router.push(`/VIP/VIPClassxq?id=${item.id}`)
    //     } else {
    //       this.$router.push(`/typeclassxq?id=${item.id}`)
    //     }
    //   } else {
    //     this.$router.push(`/zhiboclassxq?id=${item.id}`)
    //   }
    // },

    // // 直播
    gkZb(item) {
      if (item.isLive == 2) {
        this.$message({
          type: 'warning',
          message: '回放生成中！'
        })
        return
      }

      if (item.genre == 2) {
        this.sub = {
          classId: item.classId,
          id: item.id,
          couseId: item.id,
          userId: this.userId,
          tenantId: this.tenantId,
          ccConfig: escape(item.ccConfig),
          startTime: item.startTime,
          ccLiveid: item.ccLiveid,
          recordId: item.recordId,
          ccRomid: item.ccRomid,
          chapterName: item.title
        }
      } else {
        this.sub = {
          id: item.id,
          classId: item.classId,
          couseId: item.id,
          userId: this.userId,
          tenantId: this.tenantId,
          ccConfig: escape(item.ccConfig),
          startTime: item.startTime,
          chapterName: item.title
        }
      }
      // let sub = {
      //   // type: '1',
      //   id: item.id,
      //   couseId: item.id,
      //   ccConfig: escape(item.ccConfig),
      //   startTime: item.startTime,
      // }

      if (item.privateClassId) {
        this.sub = {
          courseId: item.privateClassId,
          classesId: item.classId,
          sectionId: item.sectionId,
          liveStatus: item.isLive
        }
        const data = Vue.prototype.export(this.sub)
        // this.$router.push({
        //   path: `/livebofang?${data}`,
        // });
        courseCheckPlay({
          courseType: '1',
          userId: this.userId,
          chapterId: item.id,
          source: 1
        }).then((res) => {
          if (res.code == 0) {
            const routeUrl = this.$router.resolve({
              path: `/livebofangxbk?${data}`
            })
            window.open(routeUrl.href, '_blank')
          } else {
            this.lookDeatil = res.msg
            this.showLookDetail = true
          }
        })
      } else {
        const data = Vue.prototype.export(this.sub)
        // this.$router.push({
        //   path: `/livebofang?${data}`,
        // });
        courseCheckPlay({
          courseType: '1',
          userId: this.userId,
          chapterId: item.id,
          source: 1
        }).then((res) => {
          if (res.code == 0) {
            const routeUrl = this.$router.resolve({
              path: `/livebofang?${data}`
            })
            window.open(routeUrl.href, '_blank')
          } else {
            this.lookDeatil = res.msg
            this.showLookDetail = true
          }
        })
      }

      // let userInfo = getInfo();
      // if (!userInfo) {
      //   Bus.$emit("handleLogin", false);
      //   return;
      // }

      // let sub = {
      //   id: v.id,
      //   classId: this.classId,
      //   startTime: v.startTime,
      //   classProductLine: this.classProductLine,
      // }
      // for (let key in sub) {
      //   if (!sub[key] && sub[key] !== 0) {
      //     delete sub[key]
      //   }
      // }
      // let data = Vue.prototype.export(sub)
      // this.$router.push({
      //   path: `/livebofang?${data}`,
      // })
    }

    // 观看重播按钮  切换课程章节播放
    // gkcb(v) {
    //   // let userInfo = getInfo();
    //   // if (!userInfo) {
    //   //   Bus.$emit("handleLogin", false);
    //   //   return;
    //   // }
    //   if (!this.IsPurchase) {
    //     // this.$message.error("尚未购买，请先去购买课程！");
    //     this.dialogVisible = true
    //     return
    //   }
    //   if (v.isLive == 0) {
    //     this.$message.error('课程还未到开播时间')
    //     return
    //   }
    //   if (v.isLive == 2) {
    //     this.$message.error('课程直播结束')
    //     return
    //   }
    //   let sub = {
    //     id: v.id,
    //     classId: this.classId,
    //     startTime: v.startTime,
    //     classProductLine: this.classProductLine,
    //   }
    //   for (let key in sub) {
    //     if (!sub[key] && sub[key] !== 0) {
    //       delete sub[key]
    //     }
    //   }
    //   let data = Vue.prototype.export(sub)
    //   this.$router.push({
    //     path: `/livebofang?${data}`,
    //   })
    // },
  }
}
</script>
<style lang="less" scoped>
:v-deep .active {
  color: green !important;
}

.userCentRightCens {
  width: 90%;
  height: auto;
  margin: auto;

  // 标题
  p {
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #27323f;
  }

  // 缩宽
  .userCentRightCenss {
    width: 98%;
    height: auto;
    margin: auto;

    .ov-box {
      overflow-y: scroll;
      scrollbar-width: none;
      height: 348px;
      margin-top: 32px;

      .daytCont {
        width: 100%;
        opacity: 1;
        // cursor: pointer;
        margin-bottom: 16px;
        // display: flex;
        // padding: 16px;

        .courseName {
          color: #777777;
          margin-bottom: 2px;
          height: 20px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          // line-height: 20px;

          display: -webkit-box;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }

        .dfbox {
          display: flex;

          .item-img {
            flex-shrink: 0;
            width: 128px;
            height: 72px;
            background: #c4c4c4;
            border-radius: 6px 6px 6px 6px;
            opacity: 1;
            margin-right: 12px;
          }

          .content {
            width: 100%;
            height: 100%;

            .zjTitle {
              height: 40px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }

            .dateBox {
              display: flex;
              justify-content: space-between;

              align-items: flex-end;

              .date {
                color: #888888;
                font-size: 12px;
                margin-top: 5px;
              }

              .but {
                cursor: pointer;
                margin-top: 8px;
                float: right;
                width: 52px;
                height: 24px;
                background: #ffffff;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #ff5e51;
                font-size: 12px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #ff5e51;
                line-height: 22px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

.week-list {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .week-item {
    width: 86px;
    // height: 124px;
    background: #ffffff;
    border-radius: 16px 16px 16px 16px;
    text-align: center;
    justify-content: space-between;
    padding-top: 24px;

    .day {
      width: 30px;
      height: 30px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #dddddd;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #666666;
      margin-bottom: 8px;
      cursor: pointer;
    }

    .week {
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
  }

  .on {
    .day {
      background: #ff5d51;
      box-shadow: 0px 2px 4px 1px rgba(255, 19, 0, 0.30000001192092896);
      color: #fff !important;
    }
  }
}

.love-two-title {
  margin: 0px 4px;
  padding: 20px 0px;
  border-bottom: 1px solid #eee;
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}</style>
